<template>
  <div class="container">
    <Breadcrumb />
    <ShareDialog
      :visibleShareDialog="visibleShareDialog"
      :practice="currentPractice"
      :practiceType="practiceType"
      @toggleVisibleShareDialog="toggleVisibleShareDialog"
      @reload="fetchPractices"
    />
    <CopyDialog
      v-if="currentPractice"
      :visibleCopyDialog="visibleCopyDialog"
      :practiceId="currentPractice.id"
      @toggleVisibleCopyDialog="toggleVisibleCopyDialog"
    />
    <Heading :content="pageTitle"></Heading>
    <hr />
    <div>
      <Heading
        class="mb-3"
        heading="h5"
        :content="$t('practice.practiceType')"
      />
      <div>
        <button
          :class="
            `practiceTypBtn ${activeTab === 'comboTest' &&
              'activePracticeTypBtn'}`
          "
          @click="
            $router.push({
              namr: 'Practices',
              query: { activeTab: 'comboTest' }
            })
          "
        >
          {{ $t("practice.completePractice") }}
        </button>
        <button
          :class="
            `practiceTypBtn ${activeTab === 'singleTest' &&
              'activePracticeTypBtn'}`
          "
          @click="
            $router.push({
              namr: 'Practices',
              query: { activeTab: 'singleTest' }
            })
          "
        >
          {{ $t("practice.singlePractice") }}
        </button>
      </div>
    </div>
    <hr />
    <template v-if="activeTab === 'singleTest'">
      <Heading heading="h5" :content="$t('practice.singlePractice')">
        <el-button
          v-if="isRoleAdmin() || isRoleTeacher()"
          @click="createPractice"
          slot="button"
          type="primary"
          size="mini"
        >
          <i class="fas fa-plus" />
          {{ $t("practice.singlePractice") }}
        </el-button>
      </Heading>
      <el-table :data="practices" style="width: 100%;">
        <el-table-column :label="$t('column.test')">
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'PracticeResults',
                params: { practiceId: scope.row.id }
              }"
            >
              {{ scope.row.exam.title }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column :label="$t('column.teacher')">
          <template slot-scope="scope">
            <el-tooltip
              :content="
                scope.row
                  .getEditors()
                  .map(teacher => teacher.name)
                  .join(', ')
              "
              placement="top"
              popper-class="tooltipColor"
            >
              <span class="action-icon">
                {{ scope.row.getEditors().length }}
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column :label="$t('column.questionCount')">
          <template slot-scope="scope">
            <span>{{ scope.row.questions_count }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('column.time')" width="80">
          <template slot-scope="scope">
            <span>
              {{
                scope.row.has_timing
                  ? `${scope.row.timing_minutes}:${scope.row.timing_seconds}`
                  : "--"
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('column.deadline')">
          <template slot-scope="scope">
            <span>
              {{ scope.row.has_expire ? `${scope.row.expire_end}` : "--" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('column.score')"
          v-if="isRoleAdmin() || isRoleTeacher()"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.taken_practice_users.length === 0">
              N/A
            </span>
            <router-link
              v-else
              :to="{
                name: 'PracticeResults',
                params: { practiceId: scope.row.id }
              }"
            >
              <span>
                {{
                  scope.row.taken_practice_users[
                    scope.row.taken_practice_users.length - 1
                  ]
                }}
              </span>
              <span v-if="scope.row.taken_practice_users.length > 1">
                {{ `and ${scope.row.taken_practice_users.length - 1} others` }}
              </span>
            </router-link>
          </template>
        </el-table-column>
        <template v-if="isRoleAdmin() || isRoleTeacher()">
          <el-table-column :label="$t('column.share')" width="80">
            <template slot-scope="scope">
              <el-tooltip
                v-if="scope.row.take_status === EnumPracticeTakeStatuses.public"
                :content="`Public（點擊可編輯）`"
                placement="top"
                popper-class="tooltipColor"
              >
                <span
                  key="lock-open"
                  class="action-icon"
                  @click="() => toggleVisibleShareDialog(true, scope.row)"
                >
                  <i class="fas fa-lock-open" />
                </span>
              </el-tooltip>
              <el-tooltip
                v-else-if="
                  scope.row.take_status === EnumPracticeTakeStatuses.private
                "
                :content="`Private（點擊可編輯）`"
                placement="top"
                popper-class="tooltipWrong"
              >
                <span
                  key="lock"
                  class="warning-icon"
                  @click="() => toggleVisibleShareDialog(true, scope.row)"
                >
                  <i class="fas fa-lock" />
                </span>
              </el-tooltip>
              <el-tooltip
                v-else-if="
                  scope.row.take_status === EnumPracticeTakeStatuses.unlisted
                "
                :content="`Unlisted（點擊可編輯）`"
                placement="top"
                popper-class="tooltipColor"
              >
                <span
                  key="link"
                  class="action-icon"
                  @click="() => toggleVisibleShareDialog(true, scope.row)"
                >
                  <i class="fas fa-link" />
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column :label="$t('column.copy')" width="80">
            <template slot-scope="scope">
              <span
                class="action-icon"
                @click="() => toggleVisibleCopyDialog(true, scope.row)"
              >
                <i class="fas fa-copy" />
              </span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('column.edit')" width="80">
            <template slot-scope="scope">
              <router-link
                :to="{
                  name: 'EditPractices',
                  params: { practiceId: scope.row.id }
                }"
              >
                <i class="fas fa-edit" />
              </router-link>
            </template>
          </el-table-column>
          <el-table-column :label="$t('column.delete')" width="85">
            <template slot-scope="scope">
              <DeleteIconButton :onDelete="deletePractice(scope.row.id)" />
            </template>
          </el-table-column>
        </template>
        <el-table-column :label="$t('practice.startPractice')" width="120">
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'Practice',
                params: { practiceId: scope.row.id }
              }"
            >
              <i class="fas fa-play-circle" />
            </router-link>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-else>
      <Heading heading="h5" :content="$t('practice.completePractice')">
        <el-button
          v-if="isRoleAdmin() || isRoleTeacher()"
          @click="createPractice"
          slot="button"
          type="primary"
          size="mini"
        >
          <i class="fas fa-plus" />
          {{ $t("practice.completePractice") }}
        </el-button>
      </Heading>
      <el-table :data="fullPractices" style="width: 100%;">
        <el-table-column :label="$t('column.test')">
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'FullPracticeResults',
                params: { fullPracticeId: scope.row.id }
              }"
            >
              {{ scope.row.exam.title }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column :label="$t('column.sectionCount')">
          <template slot-scope="scope">
            {{ scope.row.practices_count }}
          </template>
        </el-table-column>
        <template v-if="isRoleAdmin() || isRoleTeacher()">
          <el-table-column :label="$t('column.share')" width="80">
            <template slot-scope="scope">
              <el-tooltip
                v-if="scope.row.take_status === EnumPracticeTakeStatuses.public"
                :content="`Public（點擊可編輯）`"
                placement="top"
                popper-class="tooltipColor"
              >
                <span
                  key="lock-open"
                  class="action-icon"
                  @click="() => toggleVisibleShareDialog(true, scope.row)"
                >
                  <i class="fas fa-lock-open" />
                </span>
              </el-tooltip>
              <el-tooltip
                v-else-if="
                  scope.row.take_status === EnumPracticeTakeStatuses.private
                "
                :content="`Private（點擊可編輯）`"
                placement="top"
                popper-class="tooltipWrong"
              >
                <span
                  key="lock"
                  class="warning-icon"
                  @click="() => toggleVisibleShareDialog(true, scope.row)"
                >
                  <i class="fas fa-lock" />
                </span>
              </el-tooltip>
              <el-tooltip
                v-else-if="
                  scope.row.take_status === EnumPracticeTakeStatuses.unlisted
                "
                :content="`Unlisted（點擊可編輯）`"
                placement="top"
                popper-class="tooltipColor"
              >
                <span
                  key="link"
                  class="action-icon"
                  @click="() => toggleVisibleShareDialog(true, scope.row)"
                >
                  <i class="fas fa-link" />
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column :label="$t('column.edit')" width="80">
            <template slot-scope="scope">
              <router-link
                :to="{
                  name: 'EditFullPractices',
                  params: { fullPracticeId: scope.row.id }
                }"
              >
                <i class="fas fa-edit" />
              </router-link>
            </template>
          </el-table-column>
          <el-table-column :label="$t('column.delete')" width="85">
            <template slot-scope="scope">
              <DeleteIconButton :onDelete="deletePractice(scope.row.id)" />
            </template>
          </el-table-column>
        </template>
        <el-table-column :label="$t('practice.startPractice')" width="100">
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'FullPractice',
                params: { fullPracticeId: scope.row.id }
              }"
            >
              <i class="fas fa-play-circle" />
            </router-link>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import DeleteIconButton from "@/components/button/DeleteIconButton";
import ShareDialog from "@/components/practices/ShareDialog";
import CopyDialog from "@/components/practices/CopyDialog";
import practiceApi from "@/apis/practices";
import fullPracticesApi from "@/apis/fullPractices";
import Practice from "@/views/practices/Practice";
import FullPractice from "@/views/practices/FullPractice";
import roleMixin from "@/mixins/role";
import { EnumPracticeTakeStatuses } from "@/enums";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: { Breadcrumb, DeleteIconButton, ShareDialog, CopyDialog },
  mixins: [roleMixin],
  data() {
    return {
      practices: [],
      fullPractices: [],
      currentPractice: null,
      visibleShareDialog: false,
      visibleCopyDialog: false,
      activeTab: "comboTest"
    };
  },
  computed: {
    pageTitle() {
      return `${this.$t("pageTitle.Practices")}`;
    },
    isFullPractice() {
      return this.activeTab === "comboTest";
    },
    practiceType() {
      if (this.isFullPractice) {
        return "fullPractice";
      }
      return "practice";
    },
    EnumPracticeTakeStatuses() {
      return EnumPracticeTakeStatuses;
    }
  },
  created() {
    this.activeTab = this.$route.query.activeTab || this.activeTab;
    this.fetchPractices();
  },
  methods: {
    toggleVisibleShareDialog(status, practice) {
      this.visibleShareDialog = status;
      this.currentPractice = practice;
    },
    toggleVisibleCopyDialog(status, practice) {
      this.visibleCopyDialog = status;
      this.currentPractice = practice;
    },
    async fetchPractices() {
      if (this.isFullPractice) {
        const {
          compose_practices
        } = await fullPracticesApi.fetchFullPractices();
        this.fullPractices = compose_practices.map(
          practice => new FullPractice(practice)
        );
      } else {
        const { practices } = await practiceApi.fetchPractices();
        this.practices = practices.map(practice => new Practice(practice));
      }
    },
    async createPractice() {
      try {
        await this.$confirm(
          this.$t("message.areYouSureCreatePractices"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }

      try {
        if (this.isFullPractice) {
          const {
            compose_practice: { id }
          } = await fullPracticesApi.createFullPractice(new FullPractice());
          this.$router.push({
            name: "EditFullPractices",
            params: { fullPracticeId: id }
          });
        } else {
          const {
            practice: { id }
          } = await practiceApi.createPractice(new Practice());
          this.$router.push({
            name: "EditPractices",
            params: { practiceId: id }
          });
        }
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    deletePractice(practiceId) {
      return async () => {
        if (this.isFullPractice) {
          await fullPracticesApi.deleteFullPractice(practiceId);
        } else {
          await practiceApi.deletePractice(practiceId);
        }
        this.fetchPractices();
      };
    }
  }
};
</script>

<style scoped>
.practiceTypeTitle {
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: bold;
  text-align: right;
}

.practiceTypBtn {
  padding: 4px 0px;
  border: none;
  border-radius: 4px;
  width: 40%;
  margin: 0px 8px;
  color: #43a06b;
  background: #f5f5f5;
}

.practiceTypBtn:hover {
  color: #ffffff;
  background: #43a06b;
  box-shadow: 1px 3px 6px #aaa;
}

.activePracticeTypBtn {
  color: #ffffff;
  background: #43a06b;
  box-shadow: 1px 3px 6px #aaa;
}
</style>
