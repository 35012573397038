var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('Breadcrumb'),_c('ShareDialog',{attrs:{"visibleShareDialog":_vm.visibleShareDialog,"practice":_vm.currentPractice,"practiceType":_vm.practiceType},on:{"toggleVisibleShareDialog":_vm.toggleVisibleShareDialog,"reload":_vm.fetchPractices}}),(_vm.currentPractice)?_c('CopyDialog',{attrs:{"visibleCopyDialog":_vm.visibleCopyDialog,"practiceId":_vm.currentPractice.id},on:{"toggleVisibleCopyDialog":_vm.toggleVisibleCopyDialog}}):_vm._e(),_c('Heading',{attrs:{"content":_vm.pageTitle}}),_c('hr'),_c('div',[_c('Heading',{staticClass:"mb-3",attrs:{"heading":"h5","content":_vm.$t('practice.practiceType')}}),_c('div',[_c('button',{class:`practiceTypBtn ${_vm.activeTab === 'comboTest' &&
            'activePracticeTypBtn'}`,on:{"click":function($event){return _vm.$router.push({
            namr: 'Practices',
            query: { activeTab: 'comboTest' }
          })}}},[_vm._v(" "+_vm._s(_vm.$t("practice.completePractice"))+" ")]),_c('button',{class:`practiceTypBtn ${_vm.activeTab === 'singleTest' &&
            'activePracticeTypBtn'}`,on:{"click":function($event){return _vm.$router.push({
            namr: 'Practices',
            query: { activeTab: 'singleTest' }
          })}}},[_vm._v(" "+_vm._s(_vm.$t("practice.singlePractice"))+" ")])])],1),_c('hr'),(_vm.activeTab === 'singleTest')?[_c('Heading',{attrs:{"heading":"h5","content":_vm.$t('practice.singlePractice')}},[(_vm.isRoleAdmin() || _vm.isRoleTeacher())?_c('el-button',{attrs:{"slot":"button","type":"primary","size":"mini"},on:{"click":_vm.createPractice},slot:"button"},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("practice.singlePractice"))+" ")]):_vm._e()],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.practices}},[_c('el-table-column',{attrs:{"label":_vm.$t('column.test')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
              name: 'PracticeResults',
              params: { practiceId: scope.row.id }
            }}},[_vm._v(" "+_vm._s(scope.row.exam.title)+" ")])]}}],null,false,4284707289)}),_c('el-table-column',{attrs:{"label":_vm.$t('column.teacher')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{attrs:{"content":scope.row
                .getEditors()
                .map(teacher => teacher.name)
                .join(', '),"placement":"top","popper-class":"tooltipColor"}},[_c('span',{staticClass:"action-icon"},[_vm._v(" "+_vm._s(scope.row.getEditors().length)+" ")])])]}}],null,false,1421482874)}),_c('el-table-column',{attrs:{"label":_vm.$t('column.questionCount')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.questions_count))])]}}],null,false,1532455975)}),_c('el-table-column',{attrs:{"label":_vm.$t('column.time'),"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(" "+_vm._s(scope.row.has_timing ? `${scope.row.timing_minutes}:${scope.row.timing_seconds}` : "--")+" ")])]}}],null,false,3940767090)}),_c('el-table-column',{attrs:{"label":_vm.$t('column.deadline')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(" "+_vm._s(scope.row.has_expire ? `${scope.row.expire_end}` : "--")+" ")])]}}],null,false,2391215892)}),(_vm.isRoleAdmin() || _vm.isRoleTeacher())?_c('el-table-column',{attrs:{"label":_vm.$t('column.score')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.taken_practice_users.length === 0)?_c('span',[_vm._v(" N/A ")]):_c('router-link',{attrs:{"to":{
              name: 'PracticeResults',
              params: { practiceId: scope.row.id }
            }}},[_c('span',[_vm._v(" "+_vm._s(scope.row.taken_practice_users[ scope.row.taken_practice_users.length - 1 ])+" ")]),(scope.row.taken_practice_users.length > 1)?_c('span',[_vm._v(" "+_vm._s(`and ${scope.row.taken_practice_users.length - 1} others`)+" ")]):_vm._e()])]}}],null,false,2459252689)}):_vm._e(),(_vm.isRoleAdmin() || _vm.isRoleTeacher())?[_c('el-table-column',{attrs:{"label":_vm.$t('column.share'),"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.take_status === _vm.EnumPracticeTakeStatuses.public)?_c('el-tooltip',{attrs:{"content":`Public（點擊可編輯）`,"placement":"top","popper-class":"tooltipColor"}},[_c('span',{key:"lock-open",staticClass:"action-icon",on:{"click":() => _vm.toggleVisibleShareDialog(true, scope.row)}},[_c('i',{staticClass:"fas fa-lock-open"})])]):(
                scope.row.take_status === _vm.EnumPracticeTakeStatuses.private
              )?_c('el-tooltip',{attrs:{"content":`Private（點擊可編輯）`,"placement":"top","popper-class":"tooltipWrong"}},[_c('span',{key:"lock",staticClass:"warning-icon",on:{"click":() => _vm.toggleVisibleShareDialog(true, scope.row)}},[_c('i',{staticClass:"fas fa-lock"})])]):(
                scope.row.take_status === _vm.EnumPracticeTakeStatuses.unlisted
              )?_c('el-tooltip',{attrs:{"content":`Unlisted（點擊可編輯）`,"placement":"top","popper-class":"tooltipColor"}},[_c('span',{key:"link",staticClass:"action-icon",on:{"click":() => _vm.toggleVisibleShareDialog(true, scope.row)}},[_c('i',{staticClass:"fas fa-link"})])]):_vm._e()]}}],null,false,3114103235)}),_c('el-table-column',{attrs:{"label":_vm.$t('column.copy'),"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"action-icon",on:{"click":() => _vm.toggleVisibleCopyDialog(true, scope.row)}},[_c('i',{staticClass:"fas fa-copy"})])]}}],null,false,2368141335)}),_c('el-table-column',{attrs:{"label":_vm.$t('column.edit'),"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
                name: 'EditPractices',
                params: { practiceId: scope.row.id }
              }}},[_c('i',{staticClass:"fas fa-edit"})])]}}],null,false,2287880646)}),_c('el-table-column',{attrs:{"label":_vm.$t('column.delete'),"width":"85"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('DeleteIconButton',{attrs:{"onDelete":_vm.deletePractice(scope.row.id)}})]}}],null,false,1316839643)})]:_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('practice.startPractice'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
              name: 'Practice',
              params: { practiceId: scope.row.id }
            }}},[_c('i',{staticClass:"fas fa-play-circle"})])]}}],null,false,4142353294)})],2)]:[_c('Heading',{attrs:{"heading":"h5","content":_vm.$t('practice.completePractice')}},[(_vm.isRoleAdmin() || _vm.isRoleTeacher())?_c('el-button',{attrs:{"slot":"button","type":"primary","size":"mini"},on:{"click":_vm.createPractice},slot:"button"},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("practice.completePractice"))+" ")]):_vm._e()],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.fullPractices}},[_c('el-table-column',{attrs:{"label":_vm.$t('column.test')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
              name: 'FullPracticeResults',
              params: { fullPracticeId: scope.row.id }
            }}},[_vm._v(" "+_vm._s(scope.row.exam.title)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.sectionCount')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.practices_count)+" ")]}}])}),(_vm.isRoleAdmin() || _vm.isRoleTeacher())?[_c('el-table-column',{attrs:{"label":_vm.$t('column.share'),"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.take_status === _vm.EnumPracticeTakeStatuses.public)?_c('el-tooltip',{attrs:{"content":`Public（點擊可編輯）`,"placement":"top","popper-class":"tooltipColor"}},[_c('span',{key:"lock-open",staticClass:"action-icon",on:{"click":() => _vm.toggleVisibleShareDialog(true, scope.row)}},[_c('i',{staticClass:"fas fa-lock-open"})])]):(
                scope.row.take_status === _vm.EnumPracticeTakeStatuses.private
              )?_c('el-tooltip',{attrs:{"content":`Private（點擊可編輯）`,"placement":"top","popper-class":"tooltipWrong"}},[_c('span',{key:"lock",staticClass:"warning-icon",on:{"click":() => _vm.toggleVisibleShareDialog(true, scope.row)}},[_c('i',{staticClass:"fas fa-lock"})])]):(
                scope.row.take_status === _vm.EnumPracticeTakeStatuses.unlisted
              )?_c('el-tooltip',{attrs:{"content":`Unlisted（點擊可編輯）`,"placement":"top","popper-class":"tooltipColor"}},[_c('span',{key:"link",staticClass:"action-icon",on:{"click":() => _vm.toggleVisibleShareDialog(true, scope.row)}},[_c('i',{staticClass:"fas fa-link"})])]):_vm._e()]}}],null,false,3114103235)}),_c('el-table-column',{attrs:{"label":_vm.$t('column.edit'),"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
                name: 'EditFullPractices',
                params: { fullPracticeId: scope.row.id }
              }}},[_c('i',{staticClass:"fas fa-edit"})])]}}],null,false,3269651078)}),_c('el-table-column',{attrs:{"label":_vm.$t('column.delete'),"width":"85"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('DeleteIconButton',{attrs:{"onDelete":_vm.deletePractice(scope.row.id)}})]}}],null,false,1316839643)})]:_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('practice.startPractice'),"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
              name: 'FullPractice',
              params: { fullPracticeId: scope.row.id }
            }}},[_c('i',{staticClass:"fas fa-play-circle"})])]}}])})],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }